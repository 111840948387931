<template>
  <div
    v-if="loggedUser.roles.includes('super-admin') || item.icrea_remarks"
    class="card"
    style="border: 1px solid orange;"
  >
    <div
      v-b-toggle="`collapseIcreaRemark`"
      class="card-header"
    >
      <h3 class="cart-title">
        <a data-action="collapse">ICREA's remarks</a>
      </h3>
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li>
            <a data-action="collapse"><i data-feather="chevron-down" /></a>
          </li>
        </ul>
      </div>
    </div>
    <b-collapse
      :id="`collapseIcreaRemark`"
      :visible="visible"
    >
      <div class="card-content collapse show"> <!-- sólo se muestra "show" si hay texto en el textarea -->
        <div class="card-body pt-0">
          <div class="row">
            <div class="col">
              <div class="mb-1">
                <label
                  for=""
                  class="form-label"
                >{{ labels['content'] }}</label>
                <quill-editor
                  v-if="loggedUser.roles.includes('super-admin')"
                  v-model="item.icrea_remarks"
                />
                <div
                  v-else
                  v-html="item.icrea_remarks"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import Vue from 'vue'
import { BCollapse } from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BCollapse,
  },
  props: {
    item: { type: Object, required: true, default: () => {} },
    visible: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      labels: [],
    }
  },
  computed: {
    ...mapGetters({
      loggedUser: 'auth/admin',
    }),
  },
  async mounted() {
    try {
      const { data } = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/section-labels/showLabel/components.icrea_remarks`)
      this.labels = data.data
    } catch (e) {
      console.error(e)
    }
  },
}
</script>

<style>

</style>
