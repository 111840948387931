<template>
  <!-- BEGIN: Content-->
  <div class="card">
    <div
      v-b-toggle="`collapseContracted`"
      class="card-header"
    >
      <h3 class="cart-title">
        <a data-action="collapse">Information of {{ grant.type.name }}</a>
      </h3>
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li>
            <a data-action="collapse"><i data-feather="chevron-down" /></a>
          </li>
        </ul>
      </div>
    </div>
    <b-collapse
      :id="`collapseContracted`"
      visible
    >
      <div class="card-content">
        <div class="card-body">
          <div
            v-if="grant.type.type !== 'personal'"
            class="row"
          >
            <div class="col-md-8">
              <div class="mb-1 form-group required">
                <label
                  for=""
                  class="form-label"
                >{{ labels['information.title'] }} *</label>
                <input
                  v-model="grant.title"
                  type="text"
                  class="form-control"
                >
              </div>
            </div>
            <div
              v-if="grant.type.type === 'regular'"
              class="col-md-2"
            >
              <div class="mb-1">
                <label
                  for=""
                  class="form-label"
                >{{ labels['information.code'] }} <span
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Lorem ipsum dolor sit amet"
                ><i data-feather="info" /></span></label>
                <input
                  v-model="grant.code"
                  type="text"
                  class="form-control"
                >
              </div>
            </div>
            <div
              v-if="grant.funding_type"
              class="col-md-2"
            >
              <div class="mb-1 form-group required"><!-- según el type of funding (private sector y other) se queda en pending, cualquier otra opción ya se pone como accepted. En el crud de type of funding tener en cuenta poner un campo "status por defecto". Esto sólo es editable por ADMIN -->
                <label
                  for=""
                  class="form-label"
                >{{ labels['information.status_authorization'] }}</label>
                <v-select
                  v-model="grant.status"
                  :disabled="!loggedUser.roles.includes('super-admin')"
                  label="status"
                  :options="statuses"
                  :get-option-key="option => option.id"
                />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div class="mb-1">
                <div class="form-check form-check-success form-switch d-flex align-items-center">
                  <input
                    v-model="grant.publish_in_web"
                    type="checkbox"
                    class="form-check-input"
                    checked
                  >
                  <label
                    class="form-check-label"
                    for=""
                  ><small class="ms-1">{{ labels['information.active'] }} </small></label>
                </div>
              </div>
            </div>
          </div>

          <hr>

          <div class="row">
            <div
              v-if="grant.type.type === 'personal'"
              class="col-md-2"
            >
              <div class="mb-1 form-group required">
                <label
                  for=""
                  class="form-label w-100"
                >{{ labels['information.type_personnel'] }}
                </label>
                <div
                  class="mb-1"
                >
                  <v-select
                    v-model="grant.personnel_type"
                    label="name"
                    :clearable="false"
                    :options="types"
                    :get-option-key="option => option.id"
                    @search="onSearch({ name: $event }, 'typesPersonnel/filter')"
                  />
                </div>
              </div>
            </div>
            <div
              class="col-md-3"
            >
              <div class="mb-1 form-group required">
                <label
                  for=""
                  class="form-label w-100"
                >{{ labels['information.type'] }}
                  <span
                    :id="`field-funding-type`"
                  ><i data-feather="info" /></span>
                  <!-- <a
                    class="float-end text-danger"
                    @click="toggleFundingType"
                  ><i data-feather="alert-triangle" /> <u>Click here</u> if the institution is not in this list</a> -->
                </label> <!-- hacer crud básico -->
                <b-tooltip
                  triggers="hover"
                  :target="`field-funding-type`"
                >
                  <span v-html="'If you do not find your type, please contact the administrator.'" />
                </b-tooltip>
                <div
                  v-if="!manualFundingType"
                  class="mb-1"
                >
                  <v-select
                    v-model="grant.funding_type"
                    label="name"
                    :options="funding_types"
                    :get-option-key="option => option.id"
                    @input="selectStatus"
                  />
                </div>
              </div>
              <div
                v-if="manualFundingType"
                id="otherTypeFunding"
                class="mb-1"
              >
                <input
                  v-model="grant.funding_type_manual"
                  type="text"
                  class="form-control"
                >
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-1 form-group required">
                <label
                  for=""
                  class="form-label w-100"
                >{{ labels['information.agency_name'] }}
                  <!-- <a
                    class="float-end text-danger"
                    @click="toggleFundingAgency"
                  ><i data-feather="alert-triangle" /> <u>Click here</u> if the institution is not in this list</a> -->
                </label> <!-- hacer crud básico -->
                <div
                  v-if="!manualFudingAgency"
                  class="mb-1"
                >
                  <v-select
                    v-model="grant.agency"
                    label="name"
                    :options="funding_agencies"
                    :get-option-key="option => option.id"
                    @input="selectProgrammes"
                  />
                </div>
                <div
                  v-if="manualFudingAgency"
                  id="otherTypeFunding"
                  class="mb-1"
                >
                  <input
                    v-model="grant.funding_agency_manual"
                    placeholder="Type the name of the agency"
                    type="text"
                    class="form-control"
                  >
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="mb-1 form-group required">
                <label
                  for=""
                  class="form-label w-100"
                >{{ labels['information.type_programme'] }}
                  <!-- <a
                    class="float-end text-danger"
                    @click="toggleProgramme"
                  ><i data-feather="alert-triangle" /> <u>Click here</u> if the institution is not in this list</a> -->
                </label> <!-- hacer crud básico -->
                <div
                  v-if="!manualProgramme"
                  class="mb-1"
                >
                  <v-select
                    v-model="grant.programme"
                    label="name"
                    :options="programmes"
                    :get-option-key="option => option.id"
                  />
                </div>
                <div
                  v-if="manualProgramme"
                  id="otherTypeFunding"
                  class="mb-1"
                >
                  <input
                    v-model="grant.programme_manual"
                    placeholder="Type the name of the programme"
                    type="text"
                    class="form-control"
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div
              v-if="grant.type.type != 'personal'"
              class="col-md-6"
            >
              <div class="mb-1 form-group required">
                <label
                  for=""
                  class="form-label w-100"
                >{{ labels['information.position_grant'] }}
                </label> <!-- hacer crud básico -->
                <div
                  v-if="!manualPosition"
                  class="mb-1"
                >
                  <v-select
                    v-model="grant.position"
                    label="name"
                    :options="positions"
                    :get-option-key="option => option.id"
                  />
                </div>
                <div
                  v-if="manualPosition"
                  id="otherTypeFunding"
                  class="mb-1"
                >
                  <input
                    v-model="grant.position_manual"
                    type="text"
                    class="form-control"
                  >
                </div>
              </div>
              <div
                v-if="grant.position && grant.position.info_message"
                id="textPosition"
                class="alert alert-info"
              > <!-- este texto aparece asociado a unas opciones del select -ver crud- -->
                <div class="alert-body">
                  <p v-html="grant.position.info_message" />
                </div>
              </div>
            </div>
            <div
              v-else
              class="col-md-6"
            >
              <div class="row">
                <div class="col-md-8">
                  <div class="mb-1 form-group required">
                    <label
                      for=""
                      class="form-label w-100"
                    >{{ labels['information.name_holder'] }}
                    </label>
                    <input
                      v-model="grant.holder_name"
                      type="text"
                      class="form-control"
                    >
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="mb-1 form-group required">
                    <label
                      for=""
                      class="form-label"
                    >{{ labels['information.dedication'] }}</label>
                    <div class="input-group">
                      <v-select
                        v-model="grant.dedication"
                        style="width: 74%;"
                        label="name"
                        :options="dedications"
                        :get-option-key="option => option.name"
                        :reduce="option => option.value"
                      />
                      <!-- <input
                        v-model="grant.dedication"
                        type="number"
                        class="form-control"
                      > -->
                      <a
                        class="btn btn-outline-primary"
                      >%</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="hostOptions && hostOptions.length > 0"
              class="col-md-6"
            >
              <div class="mb-1 form-group required">
                <label
                  for=""
                  class="form-label w-100"
                >{{ labels['information.host_institution'] }}
                </label> <!-- hacer crud básico -->
                <div
                  v-if="!manualHost"
                  class="mb-1"
                >
                  <v-select
                    v-model="grant.host"
                    label="name"
                    :clearable="false"
                    :options="hostOptions"
                    :disabled="hostOptions.length === 0"
                    :get-option-key="option => option.id"
                  />
                </div>
              </div>
            </div>
          </div>

          <hr>

          <h4>Period of the grant</h4>
          <div class="row">
            <div class="col-md-3">
              <div class="mb-1 form-group required">
                <label
                  for=""
                  class="form-label"
                >{{ labels['information.begin_date'] }}</label>
                <date-picker
                  v-model="grant.begin_date"
                  format="D MMM Y"
                  value-type="format"
                  class="w-100"
                />
              </div>
            </div>
            <div class="col-md-3">
              <div class="mb-1 form-group required">
                <label
                  for=""
                  class="form-label"
                >{{ labels['information.end_date'] }}</label>
                <date-picker
                  v-model="grant.end_date"
                  format="D MMM Y"
                  value-type="format"
                  class="w-100"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-1">
                <label
                  for=""
                  class="form-label"
                >{{ labels['information.link'] }}</label>
                <div class="input-group">
                  <input
                    v-model="grant.resolution_link"
                    type="text"
                    class="form-control"
                    placeholder="https://www.icrea.cat"
                    aria-label="https://www.icrea.cat"
                    aria-describedby="basic-addon2"
                  >
                  <a
                    id="basic-addon2"
                    :href="grant.resolution_link"
                    target="_blank"
                    class="input-group-text"
                  ><i data-feather="external-link" /></a>
                </div>
              </div>
            </div>
          </div>

          <hr>

          <div class="row">
            <div class="col-sm-6">
              <h5>Industrial property
                <span
                  :id="`field-industrial-property`"
                ><i data-feather="info" /></span>
              </h5>
              <b-tooltip
                triggers="hover"
                :target="`field-industrial-property`"
              >
                <span v-html="'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'" />
              </b-tooltip>
              <div class="mb-1">
                <div class="form-check form-check-inline">
                  <input
                    id="inlineRadio1"
                    v-model="grant.industrial_property"
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    :checked="grant.industrial_property == 0"
                    value="0"
                  >
                  <label
                    class="form-check-label"
                    for="inlineRadio1"
                  >No</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    id="inlineRadio2"
                    v-model="grant.industrial_property"
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    :checked="grant.industrial_property == 1"
                    value="1"
                  >
                  <label
                    class="form-check-label"
                    for="inlineRadio2"
                  >Yes</label>
                </div>
              </div>
              <div
                v-if="grant.industrial_property == true"
                class="mb-1"
              >
                <label
                  for=""
                  class="form-label"
                >{{ labels['information.description'] }}</label>
                <textarea
                  id=""
                  v-model="grant.industrial_property_description"
                  name=""
                  cols="30"
                  rows="3"
                  class="form-control"
                />
                <!-- sólo aperece si se marca el checkbox -->
              </div>
            </div>
          </div>

        </div>
      </div>
    </b-collapse>
  </div>
  <!-- END: Content-->
</template>

<script>
import { mapGetters } from 'vuex'
import { BCollapse, BTooltip } from 'bootstrap-vue'
import vSelect from 'vue-select'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

export default {
  components: {
    BCollapse,
    vSelect,
    DatePicker,
    BTooltip,
  },
  props: {
    researcher: { type: Object, required: false, default: () => null },
  },
  data() {
    return {
      manualFundingType: false,
      manualFudingAgency: true,
      manualProgramme: true,
      manualPosition: false,
      manualHost: false,
      dedications: [],
      hostOptions: [],
      isEditing: this.$route.params.id ? true : false,
    }
  },
  computed: {
    ...mapGetters({
      grant: 'grants/item',
      statuses: 'status/statuses',
      funding_types: 'typesFunding/items',
      funding_agencies: 'fundingAgencies/items',
      positions: 'grantPositions/items',
      programmes: 'programmes/items',
      hosts: 'institutions/institutions',
      loggedUser: 'auth/admin',
      types: 'typesPersonnel/items',
      labels: 'sectionLabels/itemLabels',
    }),
  },
  watch: {
    researcher() {
      // console.log('el researcher ha cambiado ha cambiado')
      this.validateHosts()
    },
  },
  async mounted() {
    for (let index = 1; index <= 100; index++) {
      this.dedications.push({ name: index, value: index })
    }

    this.$store.dispatch('status/filterStatus', 'Types of funding')

    // if (this.types.length === 0) {
    //   this.$store.dispatch('typesPersonnel/filter')
    // }
    this.$store.dispatch('typesPersonnel/filter')

    // if (this.funding_types.length === 0) {
    //   this.$store.dispatch('typesFunding/filter')
    // }
    this.$store.dispatch('typesFunding/filter')

    // if (this.positions.length === 0) {
    //   this.$store.dispatch('grantPositions/filter')
    // }
    this.$store.dispatch('grantPositions/filter')

    if (this.hosts.length === 0) {
      this.$store.dispatch('institutions/filter')
    }

    // if(this.grant && this.grant.user && this.grant.user.hosts === 1) {
    //   const host = this.loggedUser.hosts[0]
    //   this.grant.host = host
    //   this.hostOptions = []
    
    // } else if (this.grant && this.grant.user && this.grant.user.hosts > 1) {
    //   this.hostOptions = this.grant.user.hosts
    
    // } else if (this.loggedUser.hosts && this.loggedUser.hosts.length === 1) {
    //   const host = this.loggedUser.hosts[0]
    //   this.grant.host = host
    //   this.hostOptions = []
    
    // } else if (this.loggedUser.hosts && this.loggedUser.hosts.length > 1) {
    //   this.hostOptions = this.loggedUser.hosts

    // }
    this.validateHosts()

    // this.manualFudingAgency = this.grant.funding_agency_manual != null
    // this.manualProgramme = this.grant.programme_manual != null
    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    validateHosts() {
      // console.log('dentro de la funcion')
      if(!this.isEditing) {
        // console.log('checkiando')
        if (this.grant && this.grant.user && this.grant.user.hosts.length === 1) {
          // console.log('Tiene user seleccionado y éste tiene 1 solo host')
          const host = this.grant.user.hosts[0]
          this.grant.host = host
          this.hostOptions = []
        
        } else if (this.grant && this.grant.user && this.grant.user.hosts.length > 1) {
          // console.log('Tiene user seleccionado y éste tiene varios hosts')
          this.hostOptions = this.grant.user.hosts
        
        } else if (this.grant && this.grant.user && this.grant.user.hosts.length === 0) {
          // console.log('Tiene user seleccionado y éste no tiene hosts')
          this.hostOptions = []
          this.grant.host = null

        } else if (this.loggedUser.hosts && this.loggedUser.hosts.length === 1) {
          // console.log('No tiene user seleccionado pero el logged tiene 1 host')
          const host = this.loggedUser.hosts[0]
          this.grant.host = host
          this.hostOptions = []
        
        } else if (this.loggedUser.hosts && this.loggedUser.hosts.length > 1) {
          // console.log('No tiene user seleccionado pero el logged tiene varios host')
          this.hostOptions = this.loggedUser.hosts
  
        } else {
          // console.log('Imagino que es cuando tenga usuario o no, no tiene hosts')
          this.hostOptions = []
          this.grant.host = null
        }
      } else if (this.grant.host) {
        if(this.grant.user) {
          if (this.grant.user && this.grant.user.hosts.length > 1) {
            this.hostOptions = this.grant.user.hosts
          }
        }
      }
    },
    async onSearch(search, store) {
      search.active = true
      if (search.name !== '') {
        await this.$store.dispatch(store, search)
      }
    },
    toggleFundingType() {
      this.manualFundingType = !this.manualFundingType
    },
    toggleFundingAgency() {
      this.manualFudingAgency = !this.manualFudingAgency
      this.grant.funding_agency_manual = ''
      this.grant.agency = null
    },
    toggleProgramme() {
      this.manualProgramme = !this.manualProgramme
      this.grant.programme_manual = ''
      this.grant.programme = null
    },
    togglePosition() {
      this.manualPosition = !this.manualPosition
    },
    toggleHost() {
      this.manualHost = !this.manualHost
    },
    selectStatus(data) {
      if (data) {
        if (data && data.status) {
          this.grant.status = data.status
        }

        this.$store.dispatch('fundingAgencies/filter', {
          type: data.id,
        })
      } else {
        this.grant.status = null
        this.$store.dispatch('fundingAgencies/cleanType')
      }
    },
    selectProgrammes(data) {
      if (data) {
        if (data && data.status) {
          this.grant.status = data.status
        }

        this.$store.dispatch('programmes/filter', {
          agency: data.id,
        })
      } else {
        this.$store.dispatch('programmes/cleanType')
      }
    },
  },
}
</script>
