<template>
  <!-- BEGIN: Content-->
  <div class="card">

    <div
      v-b-toggle="`collapseMonetary`"
      class="card-header"
    >
      <h3 class="cart-title">
        <a data-action="collapse">Information of Host support - Monetary</a>
      </h3>
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li>
            <a data-action="collapse"><i data-feather="chevron-down" /></a>
          </li>
        </ul>
      </div>
    </div>
    <b-collapse
      :id="`collapseMonetary`"
      visible
    >
      <div class="card-content">
        <div class="card-body">
          <div class="row">
            <div class="col-md-4">
              <div class="mb-1">
                <div class="form-check form-check-inline">
                  <input
                    id="monetarySupport1"
                    v-model="grant.monetary_type"
                    class="form-check-input"
                    type="radio"
                    name="monetarySupport"
                    value="Startup"
                  >
                  <label
                    class="form-check-label"
                    for="monetarySupport1"
                  >Start-up funding <span
                    id="startup"
                  ><i data-feather="info" /></span></label>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-1">
                <div class="form-check form-check-inline">
                  <input
                    id="monetarySupport1"
                    v-model="grant.monetary_type"
                    class="form-check-input"
                    type="radio"
                    name="monetarySupport"
                    value="Equipment"
                  >
                  <label
                    class="form-check-label"
                    for="monetarySupport1"
                  >Equipment funding <span
                    id="equipment"
                  ><i data-feather="info" /></span></label>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-1">
                <div class="form-check form-check-inline">
                  <input
                    id="monetarySupport2"
                    v-model="grant.monetary_type"
                    class="form-check-input"
                    type="radio"
                    name="monetarySupport"
                    value="Base"
                  >
                  <label
                    class="form-check-label"
                    for="monetarySupport2"
                  >Base funding <span
                    id="base"
                  ><i data-feather="info" /></span> </label>
                </div>
              </div>
            </div>
          </div>

          <hr>

          <h4>Periode of the host support</h4>
          <div class="row">
            <div class="col-md-3">
              <div class="mb-1">
                <label
                  for=""
                  class="form-label"
                >{{ labels['information.begin_date'] }}</label>
                <date-picker
                  v-model="grant.begin_date"
                  format="D MMM Y"
                  value-type="format"
                  class="w-100"
                  @change="changeYear()"
                />
              </div>
            </div>
            <div class="col-md-3">
              <div class="mb-1">
                <label
                  for=""
                  class="form-label"
                >{{ labels['information.end_date'] }}</label>
                <date-picker
                  v-model="grant.end_date"
                  format="D MMM Y"
                  value-type="format"
                  class="w-100"
                  @change="changeYear()"
                />
              </div>
            </div>
            <div class="mb-1">
              <div class="form-check form-check-success form-switch d-flex align-items-center">
                <input
                  id=""
                  v-model="grant.active"
                  type="checkbox"
                  class="form-check-input"
                >
                <label
                  class="form-check-label"
                  for=""
                ><small class="ms-1">{{ labels['information.active'] }} </small></label>
              </div>
            </div>
          </div>

          <hr>
          <b-tooltip
            triggers="hover"
            :target="`startup`"
          >
            <span v-html="labels['information.start_up_funding']" />
          </b-tooltip>
          <b-tooltip
            triggers="hover"
            :target="`equipment`"
          >
            <span v-html="labels['information.equipment_funding']" />
          </b-tooltip>
          <b-tooltip
            triggers="hover"
            :target="`base`"
          >
            <span v-html="labels['information.base_funding']" />
          </b-tooltip>
          <div
            v-if="grant.monetary_type == 'Startup'"
            id="startupfunding"
          >
            <h5 class="mb-1">
              Start-up funding
            </h5>
            <div class="row">
              <div class="col-md-4">
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >{{ labels['information.amount'] }}</label>
                  <div class="input-group">
                    <input
                      v-model="grant.total_amount"
                      type="number"
                      step="0.01"
                      class="form-control"
                      placeholder="0,00"
                      aria-label="0,00"
                      aria-describedby="basic-addon2"
                    >
                    <span
                      id="basic-addon2"
                      class="input-group-text"
                    >€</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="grant.monetary_type == 'Base' || grant.monetary_type == 'Equipment'"
            id="basefunding"
          >
            <h5 class="mb-1">
              {{ grant.monetary_type }} funding
            </h5>
            <div class="row">
              <div
                v-if="grant.monetary_type == 'Equipment'"
                class="col-md-4"
              >
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >{{ labels['information.equipment_description'] }}</label>
                  <div class="input-group">
                    <input
                      v-model="grant.equipment_description"
                      type="text"
                      class="form-control"
                    >
                  </div>
                </div>
              </div>
              <div
                v-if="!grant.cant_quantify"
                class="col-md-4"
              >
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >{{ labels['information.amount'] }}</label>
                  <div class="input-group">
                    <input
                      v-model="grant.total_amount"
                      type="number"
                      step="0.01"
                      class="form-control"
                      placeholder="0,00"
                      aria-label="0,00"
                      aria-describedby="basic-addon2"
                    >
                    <span
                      id="basic-addon2"
                      class="input-group-text"
                    >€</span>
                  </div>
                </div>
              </div>
              <div
                v-if="grant.monetary_type == 'Equipment'"
                class="col-3"
              >
                <div class="form-check form-check-success form-switch d-flex align-items-center">
                  <input
                    id="publishContent"
                    v-model="grant.cant_quantify"
                    type="checkbox"
                    class="form-check-input"
                  >
                  <label
                    class="form-check-label"
                    for="publishContent"
                  ><small class="ms-1">{{ labels['information.cant_quantify'] }}</small></label>
                </div>
              </div>
            </div>
          </div>

          <template v-if="years.length >= 1">
            <AmountGrantTable />

            <hr>

            <div class="row">
              <div class="col">
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >{{ labels['information.description'] }}</label>
                  <textarea
                    id=""
                    v-model="grant.monetary_description"
                    name=""
                    cols=""
                    rows="5"
                    class="form-control"
                  />
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </b-collapse>
  </div>
  <!-- END: Content-->
</template>

<script>
import { mapGetters } from 'vuex'
import { BCollapse, BTooltip } from 'bootstrap-vue'
import DatePicker from 'vue2-datepicker'
import AmountGrantTable from './AmountGrantTable.vue'
import 'vue2-datepicker/index.css'

export default {
  components: {
    BCollapse,
    AmountGrantTable,
    DatePicker,
    BTooltip,
  },
  data() {
    return {
      title: 'Clean component',
    }
  },
  computed: {
    ...mapGetters({
      grant: 'grants/item',
      labels: 'sectionLabels/itemLabels',
    }),
    researchTypes() {
      return this.types.filter(item => item.parent_type === 1)
    },
    supportTypes() {
      return this.types.filter(item => item.parent_type === 2)
    },
    years() {
      let year = new Date(this.grant.begin_date).getFullYear()
      const endYear = new Date(this.grant.end_date).getFullYear()
      const years = []

      for (let i = year; i <= endYear; i++) {
        years.push(year)
        year++
      }
      return years
    },
  },
  async mounted() {
    await this.$store.dispatch('typesResource/fetch')
    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    changeYear() {
      this.grant.amounts = []
      this.$forceUpdate()
    },
  },
}
</script>
