<template>
  <!-- BEGIN: Content-->
  <div>
    <hr>
    <h4>Distribution by year</h4>
    <p>Automatic (distributes {{ grant.type.type === 'monetary' ? 'equally the amount given' : 'the amount given to your group/research unit' }} ) or manual (you must enter the values)</p>
    <div class="mb-2">
      <a
        class="btn btn-primary"
        @click="autoMode"
      ><i data-feather="cpu" /> Click here to evenly distribute amounts</a>
      <!-- <a
        href=""
        class="btn btn-primary ms-25"
      ><i data-feather="edit-2" /> Manual</a> -->
    </div>
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th>Year</th>
          <th>Amount</th>
          <th>Days</th>
          <th />
          <th />
          <th class="text-end" />
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="year, index in years"
          :key="year.year"
        >
          <td>{{ year.year }}</td>
          <td>
            <div class="input-group">
              <input
                v-model="year.amount"
                type="text"
                step="0.01"
                class="form-control"
                placeholder="0,00"
                aria-label="0,00"
                aria-describedby="basic-addon2"
                value="10.000,00"
                @change="updateYear(year, index)"
              >
              <span
                id="basic-addon2"
                class="input-group-text"
              >€</span>
            </div>
          </td>
          <td>
            <div
              class="input-group"
              :class="index == 0 || index == (years.length - 1) ? 'disabled' : ''"
            >
              <span
                id="basic-addon2"
                class="input-group-text"
              >From</span>
              <date-picker
                v-model="year.from"
                format="D MMM YYYY"
                value-type="format"
                :disabled="year.all_year || index == 0 || index == (years.length - 1)"
                class="custom-disabled"
                @change="updateYear(year, index)"
              />
            </div>
          </td>
          <td>
            <div
              class="input-group"
              :class="index == 0 || index == (years.length - 1) ? 'disabled' : ''"
            >
              <span
                id="basic-addon2"
                class="input-group-text"
              >To</span>
              <date-picker
                v-model="year.to"
                format="D MMM YYYY"
                value-type="format"
                :disabled="year.all_year || index == 0 || index == (years.length - 1)"
                class="custom-disabled"
                @change="updateYear(year, index)"
              />
            </div>
          </td>
          <td>
            <div class="form-check">
              <input
                id="inlineCheckbox1"
                v-model="year.all_year"
                class="form-check-input"
                type="checkbox"
                value="checked"
                :disabled="index == 0 || index == (years.length - 1)"
                @change="updateYear(year, index)"
              >
              <label
                class="form-check-label"
                for="inlineCheckbox1"
              >All year</label>
            </div>
          </td>
          <td>
            {{ year.days }} days <span v-if="year.amount">({{ (parseFloat(year.amount.replaceAll(',', '')) / year.days).toFixed(2) }}€ x day)</span>
          </td>
        </tr>

        <tr
          v-if="grant.total_amount_group"
          :class="totalAmount == parseFloat(grant.total_amount_group.replaceAll(',', '')) ? 'table-success' : 'table-danger'"
        >
          <td><strong>TOTAL</strong></td>
          <td>
            <strong :class="totalAmount == parseFloat(grant.total_amount_group.replaceAll(',', '')) ? 'text-success' : 'text-danger'">
              {{ totalAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} €
            </strong>
            <span
              v-if="totalAmount != parseFloat(grant.total_amount_group.replaceAll(',', ''))"
              class="text-danger ms-2"
            >Incorrect total, expected {{ grant.total_amount_group }}€</span>
          </td>
          <td />
          <td />
          <td />
          <td />
        </tr>
        <tr
          v-if="(grant.type.type === 'monetary' && grant.total_amount)"
          :class="parseFloat(totalAmount) == parseFloat(grant.total_amount.replaceAll(',', '')) ? 'table-success' : 'table-danger'"
        >
          <td><strong>TOTAL</strong></td>
          <td>
            <strong :class="parseFloat(totalAmount) == parseFloat(grant.total_amount.replaceAll(',', '')) ? 'text-success' : 'text-danger'">{{ totalAmount }} €</strong>
            <span
              v-if="parseFloat(totalAmount) != parseFloat(grant.total_amount.replaceAll(',', ''))"
              class="text-danger ms-2"
            >Incorrect total, expected {{ grant.total_amount }}€</span>
          </td>
          <td />
          <td />
          <td />
          <td />
        </tr>
      </tbody>
    </table>
  </div>
  <!-- END: Content-->
</template>

<script>
import { mapGetters } from 'vuex'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

export default {
  components: {
    DatePicker,
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters({
      grant: 'grants/item',
    }),
    totalAmount() {
      if (this.grant.amounts.length === 0) {
        return 0
      }
      return this.grant.amounts.map(item => (item.amount ? item.amount.replaceAll(',', '').replaceAll('.', ',') : 0)).reduce((prev, next) => parseFloat((prev || 0), 0) + parseFloat((next || 0), 0)) || 0
    },
    years() {
      const data = []
      const years = this.generateYearsBetween()
      years.forEach((year, index) => {
        if (years.length === 1) {
          data[year] = this.grant.amounts[this.grant.amounts.findIndex(x => x.year === year)] ?? {
            year,
            from: this.grant.begin_date,
            to: this.grant.end_date,
            days: this.getDaysMoney(this.grant.begin_date, this.grant.end_date),
          }
        } else if (index === years.length - 1) {
          data[year] = this.grant.amounts[this.grant.amounts.findIndex(x => x.year === year)] ?? {
            year,
            from: `${year}-01-01`,
            to: this.grant.end_date,
            days: this.getDaysMoney(`${year}-01-01`, this.grant.end_date),
          }
        } else if (index === 0) {
          data[year] = this.grant.amounts[this.grant.amounts.findIndex(x => x.year === year)] ?? {
            year,
            from: this.grant.begin_date,
            to: `${year}-12-31`,
            days: this.getDaysMoney(this.grant.begin_date, `${year}-12-31`),
          }
        } else {
          data[year] = this.grant.amounts[this.grant.amounts.findIndex(x => x.year === year)] ?? {
            year,
            all_year: true,
            days: this.getDaysMoney(`${year}-01-01`, `${year}-12-31`),
          }
        }

        if (this.grant.amounts[this.grant.amounts.findIndex(x => x.year === year)] === undefined) {
          this.grant.amounts.push(data[year])
        }
      })

      return data.filter(e => e !== undefined)
    },
  },
  async mounted() {
    if (this.grant.amounts) {
      this.grant.amounts.forEach((e, index) => {
        this.formatFloat(e.amount, index)
      })
    }

    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    generateYearsBetween() {
      let year = new Date(this.grant.begin_date).getFullYear()
      const endYear = new Date(this.grant.end_date).getFullYear()
      const years = []

      for (let i = year; i <= endYear; i++) {
        years.push(year)
        year++
      }
      return years
    },
    updateYear(item, index) {
      const data = item

      if (data.all_year) {
        data.from = `${data.year}-01-01`
        data.to = `${data.year}-12-31`
      }

      data.days = this.getDaysMoney(item.from, item.to)

      if (this.grant.amounts[index]) {
        this.grant.amounts.splice(index, 1, data)
      } else {
        this.grant.amounts.push(data)
      }

      this.formatFloat(item.amount, index)

      this.$forceUpdate()
    },
    formatFloat(float, index) {
      if (float) {
        const value = float.toString().replace(/[^\d.]/g, '') // Remove non-numeric characters
        const parts = value.split('.') // Split into integer and decimal parts

        // Add commas as thousands separators in the integer part
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')

        // Reconstruct the formatted value
        const formattedValue = parts.join('.')

        // Update the input value
        this.grant.amounts[index].amount = formattedValue
      }
    },
    autoMode() {
      const totalDays = this.grant.amounts.map(im => im.days).reduce((prev, next) => prev + next)

      this.grant.amounts.forEach((item, index) => {
        if (this.grant.type.type !== 'monetary') {
          item.amount = ((item.days * parseFloat(this.grant.total_amount_group.replaceAll(',', ''))) / totalDays).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        } else {
          item.amount = ((item.days * parseFloat(this.grant.total_amount.replaceAll(',', ''))) / totalDays).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        }
        this.grant.amounts.splice(index, 1, item)

        if (index === (this.grant.amounts.length - 1)) {
          if (this.grant.type.type !== 'monetary') {
            item.amount = (parseFloat(item.amount.replaceAll(',', '')) + parseFloat(parseFloat(this.grant.total_amount_group.replaceAll(',', '')) - this.totalAmount)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          } else {
            item.amount = (parseFloat(item.amount.replaceAll(',', '')) + parseFloat(this.grant.total_amount.replaceAll(',', '') - parseFloat(this.totalAmount))).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          }

          this.grant.amounts.splice(index, 1, item)
        }
      })
      this.$forceUpdate()
    },
    getDaysMoney(from, to) {
      const date1 = new Date(from)
      const date2 = new Date(to)
      const difference = date2.getTime() - date1.getTime()
      return Math.ceil(difference / (1000 * 3600 * 24))
    },
  },

}
</script>
