<template>
  <!-- BEGIN: Content-->
  <div class="card">
    <div class="card-header">
      <div class="row w-100">
        <div class="col-6">
          <h3 class="cart-title">
            Type of resource
          </h3>
        </div>
      </div>
    </div>
    <div class="card-content collapse show">
      <div class="card-body">
        <div class="alert alert-warning p-1">
          <p>
            <strong>Regular grant:</strong> ERC, MINECO, MECD, AGAUR, Marató, etc., even if you plan to hire people with all or part of this funding.
          </p>
          <p>
            <strong>Personnel grant:</strong> FI, FPI, FPU, Marie Curie, Beatriu de Pinós, Juan de la Cierva, etc. Essentially, people who come to work in your group, whose funding does not come directly from your grants.
          </p>
          <p>
            <strong>In Kind grant:</strong> This refers to things like time in telescopes, supercomputers, synchrotrons, oceanographic vessels,and the like.
          </p>
        </div>
        <h5>Grant & Contracted Research</h5>
        <div class="row">
          <div
            v-for="item in researchTypes"
            :key="item.id"
            class="col-md-4"
          >
            <div class="mb-1">
              <div class="form-check form-check-inline">
                <input
                  id="publicationType1"
                  v-model="grant.type"
                  class="form-check-input"
                  type="radio"
                  name="publicationType"
                  :checked="grant.type && grant.type.id === item.id"
                  :value="item"
                >
                <label
                  class="form-check-label"
                  for="publicationType1"
                >{{ item.name }}
                  <span
                    :id="`field-${item.id}`"
                  >
                    <i data-feather="info" />
                  </span>
                </label>
              </div>
            </div>
            <b-tooltip
              triggers="hover"
              :target="`field-${item.id}`"
            >
              <span v-html="item.description" />
            </b-tooltip>
          </div>
        </div>
        <hr>
        <div class="alert alert-warning p-1">
          <p>
            <strong>Monetary support:</strong> These funds could be Start-up funds, Equipment or Base funding.
          </p>
          <p>
            <strong>Personnel support:</strong> People hired by your Host Institution working in your research group, including those financed by María de Maeztu and Severo Ochoa awards.
          </p>
        </div>
        <h5>Host Support</h5>
        <div class="row">
          <div
            v-for="item in supportTypes"
            :key="item.id"
            class="col-md-4"
          >
            <div class="mb-1">
              <div class="form-check form-check-inline">
                <input
                  id="publicationType1"
                  v-model="grant.type"
                  class="form-check-input"
                  type="radio"
                  name="publicationType"
                  :checked="grant.type && grant.type.id === item.id"
                  :value="item"
                >
                <label
                  class="form-check-label"
                  for="publicationType4"
                >{{ item.name }}
                  <span
                    :id="`field-${item.id}`"
                  ><i data-feather="info" /></span></label>
              </div>
            </div>
            <b-tooltip
              triggers="hover"
              :target="`field-${item.id}`"
            >
              <span v-html="item.description" />
            </b-tooltip>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import { mapGetters } from 'vuex'
import { BTooltip } from 'bootstrap-vue'

export default {
  components: {
    BTooltip,
  },
  data() {
    return {
      title: 'Clean component',
    }
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      types: 'typesResource/items',
      grant: 'grants/item',
    }),
    researchTypes() {
      return this.types.filter(item => item.parent_type === 1)
    },
    supportTypes() {
      return this.types.filter(item => item.parent_type === 2)
    },
  },
  async mounted() {
    if (this.types.length === 0) {
      await this.$store.dispatch('typesResource/fetch')
    }

    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
}
</script>
